.projects-title {
  text-transform: uppercase;
  color: #B10808;
  text-align: center;
  font-family: 'Work-Sans', sans-serif;
  font-size: 3em;
  line-height: normal;
  padding-bottom: 1.5rem;
}

.projects-text {
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  font-family: 'Cinzel', sans-serif;
  font-size: 3em;
  text-align: left;
}

.pj-img {
  width: 100%;
  padding: 0 0.5em 0 0.5em;
}

.pj-box {
  padding-bottom: 6rem;
}

.pj-name {
  font-size: 2em;
  text-align: center;
}