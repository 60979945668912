.impact {
  background-color: #1B1B1C;
}

.impact-title {
  color: #ffffff;
  padding-top: 5rem;
  padding-bottom: 3rem;
  text-align: center;
  font-size: 1em;
  letter-spacing: 3px;
  text-align: left;
}

.impact-text {
  color: #FFFFFF;
  text-align: center;
  font-family: 'Cinzel', sans-serif;
  font-size: 3em;
  text-align: left;
}

.impact-img {
  text-align: center;
  max-width: 100%;
  height: auto;
  width: 100%;
}

.impact-details {
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
}

.hr {
  fill: #C41E3A;
  border: solid 2px #C41E3A;
  color:  #C41E3A;
}