.forum {
  display: flex;
  flex-grow: 1;
}

.postList {
  width: 400px;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  height: 100%;
  overflow: auto;
  /* margin-left: 20px; */
  margin-right: 20px;
}

.forumMainSearch {
  flex-grow: 2;
  height: 38px;
  margin-left: 10px;
  border: none;
}
.forumMainSearch:focus {
  outline: none;
}

.postViewer {
  width: 80%;
  max-height: 100%;
  overflow: scroll;
}

.postListHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.sectionQuestionButton {
  margin-bottom: 8px;
}


.pageContainerNoMargins {
  margin-left: -5px;
  margin-right: -11px;
}

.searchContent {
  padding: 25px;
}
