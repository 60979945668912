.news_body {
    text-transform: uppercase;
    font-weight: 300;
}


.news_img {
    width: 100%;
}

.news_heading {
    color: #B10808;
    font-size: 1em;
    letter-spacing: 3px;
    text-align: left;
}

.news_content_title {
    font-size: 2em;
    text-align: left;
    padding: 1em 0em 1em 0em;
    line-height: normal;
}

.news_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.oc_button_img {
    max-width: 90%;
    height: auto;
}

.csb-news-heading {
    text-transform: uppercase;
    color: #B10808;
    text-align: center;
    font-family: 'Work-Sans', sans-serif;
    font-size: 3em;
    line-height: normal;
    padding-bottom: 1.5rem;
}