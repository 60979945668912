.ms_body {
    text-transform: uppercase;
    font-weight: 300;
}

.ms_title {
    color: #B10808;
    font-size: 1em;
    letter-spacing: 3px;
    padding: 5em 0 0 0;
}

.ms_slogan {
    font-size: 3em;
    padding: 1em 0em 3em 0em;
}



