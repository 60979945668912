.postEntries {
  overflow: scroll;
  /* padding-left:10px; */
  padding-right: 0px;
}

.postActions {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}


.filterResolved {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.filtersHeader {
  padding: 16px;
  font-size: 14px;
  color: rgb(0, 0, 0, 0.6);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: lighter;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background-color: white;
  border-bottom: 1px solid lightgrey;
  z-index: 2;
}

.filterResolved {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
