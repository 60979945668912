.activeContainer {
  background-color: #eff1fb;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  margin: 0;
  display: flex;
}

.inactiveContainer {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  margin: 0;
  padding: 0;
  display: flex;
}

.postListEntry {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-size: 0.9em;

  padding-left: 10px;
  padding-right: 20px;
}

.activeDeleteContainer {
  background-color: rgb(239, 247, 250);
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.deleteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.postSideBar {
  /* margin-right: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postListEntryTitle {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

<<<<<<< HEAD
.postListEntryAuthor {
  font-size: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}
=======

>>>>>>> main

.postListEntryDescription {
  padding-top: 5px;
  color: grey;
  font-size: 0.8em;
}

.deleteButton:hover {
  color: red;
}

.picture {
  margin-bottom: 5px;
}

.postListEntryLeft {
  flex-grow: 2;
  white-space: nowrap;
}

.postListEntryInner {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.postEntryTags .ant-tag {
  margin-top: 6px;
  font-size: 10px;
}
