/* styles.css */
.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../assets/intro.jpeg');

  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
}


.intro-text {
  position: relative;
  color: rgb(0, 0, 0);
  font-size: 5rem;

  font-family: 'Cinzel', sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}

.intro-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: -1;
}