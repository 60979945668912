.cs_body {
    text-transform: uppercase;
    font-weight: 300;
}

.cs_title {
    text-transform: uppercase;
    color: #B10808;
    font-size: 3em;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;
    line-height: normal;
    font-family: 'Work-Sans', sans-serif;
    font-weight: 450;
}

.cs_slogan {
    font-size: 3em;
    padding: 1em 0em 1em 0em;
    text-align: left;
    line-height: normal;
}

.cs_img {
    width: 100%;
    padding: 0 0.5em 0 0.5em;
}


.oc_sl {
    color: #B10808;
    font-size: 1em;
    text-align: left;
}

.oc_name {
    font-size: 1em;
    text-align: left;
}


.oc_button_img {
    width: 100%;
}


.courses-box {
    padding-bottom: 4rem;
}
