.footer {
  background-color: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.first-line {
  margin-bottom: 0;
}

.second-line {
  margin-top: -5px; /* Adjust this value to reduce or increase the gap */
}

.contact-detail {
  margin-left: 2.5rem;
}